<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-server-table
        class="pt-0"
        ref="table"
        name="ekyc/clientsTable"
        :columns="columns"
        :options="options"
      >
        <div slot="afterFilterWrapper">
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title>{{ $t('ekyc.listing.title') }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                text
                color="primary"
                :to="{ name: 'eKYCAllianceNew' }"
              >
                <v-icon left>
                  mdi-plus-outline
                </v-icon>
                {{ $t('ekyc.listing.buttons.add') }}
              </v-btn>
            </v-app-bar>
          </v-card-title>
        </div>
        <!-- <div slot="service_id" slot-scope="props">{{ props.row.service_id }}</div>
				<div slot="create_date" slot-scope="props">
					<DateFormat :date="props.row.create_date" :showtime="true"/>
				</div>
				<div slot="hit_name" slot-scope="props">
					<router-link color="primary" :to="{ name: 'NaturalPersonEdit', params: { np_id: props.row.person.id } }" v-if="props.row.person">
						{{ props.row.hit_name }}
					</router-link>
					<span v-else>{{ props.row.hit_name }}</span>
				</div>
				<div slot="hit_status" slot-scope="props">{{ (hit_status_options.find(x => x.value == props.row.hit_status) || { text: '' }).text }}</div>
				<div slot="exposition" slot-scope="props" style="max-width: 300px;">{{ (exposition_options(props.row.hit_status).find(x => x.value == props.row.exposition) || { text: '' }).text }}</div>
				<div slot="remarks" slot-scope="props">{{ props.row.remarks }}</div> -->
        <div
          slot="action"
          slot-scope="props"
        >
          <v-btn
            icon
            :to="{ name: 'eKYCAllianceInfo', params: { ekyc_client_id: props.row.id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </v-server-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import DateFormat from '@/components/common/DateFormat'

export default {
  computed: {
    ...mapGetters({
      options: 'ekyc/listing_options'
    }),
    ...mapState('ekyc', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns
    })
  }
}
</script>

<style>

</style>
